import React from "react";

// Styles
import InvestmentCaseViewContainer from "./InvestmentCaseView.styles";

interface InvestmentCaseViewViewProps {}

const InvestmentCaseView = ({}: InvestmentCaseViewViewProps) => {
    return (
        <InvestmentCaseViewContainer>
            <div className="iframeContainer">
                <iframe
                    title="Investment Cases"
                    src="https://app.powerbi.com/reportEmbed?reportId=5b92724c-5580-481e-93cb-ecf06ff5bd6a&autoAuth=true&ctid=bf4450a2-bfcb-472d-8c40-1425eafb1a3b"
                    width="100%"
                    height="100%"
                    allowFullScreen
                    frameBorder={0}
                />
            </div>
        </InvestmentCaseViewContainer>
    );
};
export default InvestmentCaseView;
